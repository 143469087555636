html {
    /* background-image: url('./mesh-gradient1.png'); */
    background-color: #D8DEE9;
    background-size: 100% 100%;
    /* justify-content: center;
    align-items: center; */
}

.contact-card {
    justify-content: center;
    align-items: center;
}