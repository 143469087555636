.resume-page {
    background-color: #D8DEE9;
    padding: 6rem;
    margin: 0 6rem 6rem 6rem;
}
.resume-heading {
    text-align: center;
    color: #3B4252;
}
.resume-heading a{
    text-align: center;
    color: #8080FF;
}
.resume-heading:visited{
    color: #8080FF;
   
}
.page-break {
    margin-bottom: 20px;
    height: 10px;
    border-bottom: none;
    border-left: none;
}
.resume-section-heading {
    padding-bottom: 10px;
    font-size: 28px;
    color:  #8080FF;
    font-weight: 400;

}

.skills-section {
    margin: 50px 0px 100px 0px;    
}
.skills-section ul {
    text-align: center;
}
.skills-section li {
    list-style-type: none;
    display: inline-block;
    padding: 5px;
    margin: 5px 20px;
    font-size: 20px;
}

.skills-section li:hover {
    /* background-color: #FFF; */
    transform: scale(1.05);
}
.experience-section {
    max-width: 1000px;
    min-height: 1200px;
    display: flex;
    color: #3B4252;
}
.exp-container {
    position: relative;
    width: 50%;
}
.left {
    flex: 30%;
    flex-direction: column;
    max-width: 250px;
    padding: 0;
    flex-wrap: nowrap;
}
.right {
    flex: 70%;
    border-left: 3px solid;
    border-color: black;
    padding: 0;
}
.date-worked {
    height: 450px;
    width: 175px;
    float: left;
    padding-right: 10px;
    font-size: 20px;
}
.work-section {
    margin: 0px 60px;
    padding-bottom: 100px;
    display: flex;
    min-height: 300px;

}

.work-section .exp-title h3 {
    font-weight: 500;
    font-size: 26px;
}
.work-section h4 {
    font-weight: 200;
    color: #8080FF;
    font-size: 20px;    
    padding-bottom: 5px;
}
.work-section li {
    font-weight: 200;
    font-size: 16px;    
}
.body {
    /* min-height: 175px; */
    min-width: 250px;
    display: flex;    
    margin-bottom: 50px;
    padding: 0px 20px 20px 30px;
    border: 3px white thin;    
    border-radius: 5px;    
    background-color: whitesmoke;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.body::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #000;
    position: absolute;
    left: -13.5px;
}
.body .exp-title {
    padding-top: 10px;
}
.date-hidden {
    display: none;
}
.description {
    /* height: 300px;
    max-width: 1200px;
    min-width: 100px; */
    margin-bottom: 50px;
    
}

.education-section {
    margin: 100px 0px;    
    font-size: 20px;
    color: #3B4252;
}

.education-section h4{
    font-weight: 24;    
}
@media screen and (max-width: 1080px) {
    .resume-heading {
        padding-top: 2rem;
    }
    .resume-page {
        margin: 3rem;
        padding: 3rem;
    }
}
@media screen and (max-width: 820px) {
    .resume-page {
        margin: 0;
        padding: 0;
    }
    .work-section h4 {
        padding-bottom: 0;
    }
    .date-hidden {
        display: block;
        padding-bottom: 10px;
    }
    .left {
        display: none;
    }

    .right {
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .body {
        min-width: 250px;
    }

    .work-section {
        margin-right: 0;
        margin-left: 1rem;
    }
}