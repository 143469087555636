.snakeGame {
    align-items: center;
    margin: 30% 0 30% 0;
    display: flex;
    flex-direction: column;
}
.snakeGame .back{
    color: #8080FF;
    text-align: center;
    padding-bottom: 3em;
   
}