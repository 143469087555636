.footer-container {
    background-color: #3B4252;
    height: 200px;
    width: 100%;
    padding:4rem 4rem 4rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-links{
      display: flex;      
  }
  .footer-link-items {
    padding: 10px;
    color: white;
    border-bottom: thin solid black;
    background: none;
   
}
.footer-link-items > a {
  padding: 10px;
  font-size: 20px;
  color: black;
  text-decoration: none;
  background: none;
}

.footer-link-items > a:hover {
  color: white;
}
  .footer-link-icon> a {

    font-size: 20px;
    color: black;
    text-decoration: none;
    background: none;
}

.footer-container .footer-links .footer-link-icon > svg {
    background: none;
}

  /* Link{
      color: black;
  } */
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 460px) {
   .footer-links {
     flex-direction: row;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
     min-width: 350px;
   } 
}
  