.cardBox {
    max-width: 300px;
    height: 200px;
    background-color: whitesmoke;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin: 1rem;
    /* cursor: pointer; */
    overflow: hidden;
    transition: all ease 200ms;
}

.cardBox:hover {
    /* height: 400px; */
    transform: scale(1.03);
}

.cardContents {
    background-color: whitesmoke;
    padding: 10px 30px;
    height: 125px;
    color: #3B4252;
}
.inDev {
    padding-top: 15px;
    text-align: center;
    color: #8080FF;
    font-weight: bold;
}
.resources ul {
    display: inline;
}
.resources svg {
    height: 30px;
    width: 30px;
}
.resources li {
    list-style: none;
    display: inline;
}
.cardButtons {
    display: flex;
    padding: 30px 20px 0 20px;    
    justify-content: space-between;
    align-items: flex-end;
}
.cardButtons .github-icon {
    margin-top: 3px;
    color: #3B4252;
}
.cardButtons .github-icon:hover { 
    color: #3B4252;
}
.cardButtons path{
    color: #3B4252;
    bottom: 0px;
    padding: 20px;
}

.cardButtons path:hover{
    color: black;
}

@media screen and (max-width: 480px) {
    .projectLink {
        display: none;
    }
}
    