.home__project-section {
    background-color: #E5E9F0;
    padding: 0 0 300px 0;
}

.project-title{
    justify-content: center;
    display: flex;
    padding: 0 20px 20px 20px;
    margin-bottom: 3rem;
    color: #3B4252;
}
.cardHolders {
    display: flex;
    margin: 0 5%;
    flex-wrap: wrap;
    justify-content: center;
}

.home__project-section > svg {
    /* fill: #2E3440; */
    background: #D8DEE9;
    
  }