.about {
    background-color: #D8DEE9;
}
.about-section {
    margin: 0 10% 0 10%;
    display: flex;
}

.about-body h1{
    text-align: center;    
    color: #3B4252;
}
.about-body p{
    padding: 50px 50px 0 50px;    
    text-align: center;
    font-size: 20px;
    color: #3B4252;
    animation: fadeIn 1.5s ease-in; 

}

.about-description img {
    height: 300px;
    min-width: 300px;
    justify-content: flex-end;
}

.about > svg {
    /* fill: #2E3440; */
    background: #3B4252;
    
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @media screen and (max-width: 860px) {
    .about-section {
        flex-direction: column;
        align-items: center;
      }
    .about-body p{
        padding: 50px 0 0 0; 
        width: 100%;   
        text-align: center;
        font-size: 20px;
        color: #3B4252;
    
    }
    
}